dmx.imageParallax = {
    running: false,

    elements: [],

    lastPosition: -1,

    start: function(node) {
        dmx.imageParallax.elements.push(node);

        var opts = node.dmxImageParallax;

        opts.container = node;

        if (!opts.overflow) {
            opts.container = dmx.imageParallax.wrap(node);
            node.style.transform = 'scale(' + opts.scale + ')';
        }

        if (opts.delay > 0) {
            node.style.transition = 'transform ' + opts.delay + 's ' + opts.transition;
        }

        node.style.willChange = 'transform';

        if (!dmx.imageParallax.running) {
            dmx.imageParallax.running = true;
            requestAnimationFrame(dmx.imageParallax.animate);
        }
    },

    stop: function() {
        dmx.imageParallax.running = false;
    },

    animate: function() {
        if (!dmx.imageParallax.running) return;

        dmx.imageParallax.elements.forEach(function(node) {
            dmx.imageParallax.updateElement(node);
        });

        requestAnimationFrame(dmx.imageParallax.animate);
    },

    wrap: function(node) {
        var wrapper = document.createElement('div');
        wrapper.className = node.className;
        wrapper.style.display = 'inline-block';
        wrapper.style.overflow = 'hidden';
        node.parentNode.insertBefore(wrapper, node);
        wrapper.appendChild(node);
        //node.className = '';
        return wrapper;
    },

    updateElement: function(node) {
        if (!node.offsetParent) return;

        var opts = node.dmxImageParallax;
        var elmRect = opts.container.getBoundingClientRect();

        if (elmRect.width === 0 || elmRect.height === 0) return;

        /*if (opts.container != node && !opts.container.style.width) {
            opts.container.style.width = node.clientWidth + 'px';
            opts.container.style.height = node.clientHeight + 'px';
        }*/

        var viewport = dmx.imageParallax.getScrollParent(node);

        if (viewport == window) {
            var viewportRect = {
                top: 0,
                left: 0,
                right: window.innerWidth,
                bottom: window.innerHeight,
                width: window.innerWidth,
                height: window.innerHeight
            };
        } else {
            var viewportRect = viewport.getBoundingClientRect();
        }

        if (elmRect.bottom < viewportRect.top || elmRect.top > viewportRect.bottom) return;

        var perc = (viewportRect.bottom - elmRect.top) / (viewportRect.height + elmRect.height);
        var max = node.clientHeight * opts.scale - node.clientHeight;
        var val = ~~(perc * max - max / 2)

        var translateX = 0;
        var translateY = 0;

        if (opts.orientation.indexOf('left') != -1) {
            translateX = -val;
        }

        if (opts.orientation.indexOf('right') != -1) {
            translateX = val;
        }

        if (opts.orientation.indexOf('up') != -1) {
            translateY = -val;
        }

        if (opts.orientation.indexOf('down') != -1) {
            translateY = val;
        }

        var transform = 'translate3d(' + translateX + 'px, ' + translateY + 'px, 0)';

        if (!opts.overflow) {
            transform += ' scale(' + opts.scale + ')';
        }

        if (node.style.transform != transform) {
            node.style.transform = transform;
        }
    },

    isScrollParent: function(node) {
        try {
            var style = window.getComputedStyle(node);
            return (/(auto|scroll)/).test(style.overflow + style.overflowX + style.overflowY);
        } catch(e) {
            return false;
        }
    },

    getScrollParent: function(node) {
        if (!node) return window;
        if (dmx.imageParallax.isScrollParent(node)) return node;
        return dmx.imageParallax.getScrollParent(node.parentNode);
    },
};
