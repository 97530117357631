dmx.Attribute('image-parallax', 'mounted', function(node, attr) {
    node.dmxImageParallax = {
        scale: attr.modifiers.scale ? attr.modifiers.scale / 100 : 1.3,
        delay: attr.modifiers.delay ? attr.modifiers.delay / 1000 : 0.4,
        overflow: !!attr.modifiers.overflow,
        transition: 'cubic-bezier(0,0,0,1)',
        orientation: attr.value || 'up'
    };

    dmx.imageParallax.start(node);
});
